<template>
  <MasterLayout :main_name="main_name" :link="link">
    <div class="sidebar_right_content booking_content">
      <div id="Mechanics" class="w3-container w3-border city">
        <ul
          class="ui-formSlide"
          v-if="Object.keys(select_instance).length === 0"
        >
          <!-- dashboard first section -->
          <li data-step="bk_1" class="active" v-if="bookingList.length == 0">
            <div class="v_bk_cat">
              <img :src="frame" />
              <div class="v_con_main">You Have No Pending Booking</div>
              <div class="bk_btn">
                <router-link to="/villa" class="site_bk_btn">
                  <span>Book Villa</span>
                </router-link>
              </div>
            </div>
          </li>
          <!-- end dashboard first section -->
          <li
            data-step="bk_2"
            v-else
            v-for="(booking, index) in bookingList"
            :key="booking.id"
            :id="'row_' + booking.id"
          >
            <div class="mbr_detail_col_inr">
              <div class="mbr_detail_block">
                <ul class="mbr_detail_ul">
                  <li class="mbr_detail_li">
                    <div class="mbr_detail_div">
                      <div class="mbr_detail_name">
                        <span class="villa_img">
                          <a
                            :href="booking.villa_booking.villa_image"
                            data-lightbox="models"
                            data-title="STRUCTURE-1"
                          >
                            <img :src="booking.villa_booking.villa_image" />
                          </a>
                        </span>
                        <span class="light_font ml-2">{{
                          booking.villa_booking.villa_name
                        }}</span>
                      </div>
                    </div>
                  </li>
                  <li class="mbr_detail_li name_li">
                    <div class="mbr_detail_div">
                      <div class="mbr_detail_name">
                        <span class="light_font"
                          >{{ booking.person_details.first_name }}
                          {{ booking.person_details.last_name }}
                          <b class="bold_font">{{
                            booking.person_details.mobile
                          }}</b></span
                        >
                      </div>
                    </div>
                  </li>
                  <li class="mbr_detail_li">
                    <div class="mbr_detail_div">
                      <div class="mbr_detail_name light_font">
                        <span
                          ><span style="width: 85px; display: inline-block"
                            >Check In</span
                          >
                          :&nbsp; {{ booking.booking_detail.check_in_date }}<br />
                          <span style="width: 85px; display: inline-block"
                            >Check Out</span
                          >
                          :&nbsp; {{ booking.booking_detail.check_out_date }}</span
                        >
                      </div>
                    </div>
                  </li>
                  <li class="mbr_detail_li">
                    <div class="mbr_detail_div">
                      <div class="mbr_detail_name light_font">
                        <p class="price">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="rupee"
                            >₹</span
                          >{{
                            parseFloat(
                              booking.booking_detail.final_total
                                ? booking.booking_detail.final_total -
                                    (booking.booking_detail.coupon_amount
                                      ? booking.booking_detail.coupon_amount
                                      : 0)
                                : 0
                            )
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}.00
                        </p>
                      </div>
                    </div>
                  </li>
                  <li class="mbr_detail_li width_40">
                    <div class="mbr_detail_div" style="justify-content: end">
                      <div class="mbr_detail_name light_font">
                        <a
                          v-if="userProfile.user_type != 'Owner'"
                          class="btn btn-sm btn-primary text-white"
                          @click="selectBooking(booking)"
                          >{{
                            booking.free_for_friend ? "Confirm" : "Make Payment"
                          }}</a
                        >
                        <a
                          class="btn btn-sm btn-danger text-white ml-2"
                          @click="cancelBooking(booking.id)"
                          >Cancel</a
                        >
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
        <ul class="ui-formSlide" v-if="Object.keys(select_instance).length">
          <div
            class="user_wrapper"
            v-for="(item, index) in select_instance.persons"
            :key="index"
          >
            <span class="li_index">{{ index + 1 }}</span>
            <div>
              <div class="row">
                <div class="col-6">
                  <label>First Name<span style="color: red">*</span></label>
                  <input
                    type="text"
                    class="form-control"
                    name="first_name"
                    v-model="item.first_name"
                    :disabled="index === 0"
                  />
                  <div class="error" v-if="errors[index]?.first_name">
                    {{ errors[index]?.first_name[0] }}
                  </div>
                </div>
                <div class="col-6">
                  <label>Middle Name<span style="color: red"></span></label>
                  <input
                    type="text"
                    class="form-control"
                    name="middle_name"
                    v-model="item.middle_name"
                    :disabled="index === 0"
                  />
                  <div class="error" v-if="errors[index]?.middle_name">
                    {{ errors[index]?.middle_name[0] }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <label>Last Name<span style="color: red">*</span></label>
                  <input
                    type="text"
                    class="form-control"
                    name="last_name"
                    v-model="item.last_name"
                    :disabled="index === 0"
                  />
                  <div class="error" v-if="errors[index]?.last_name">
                    {{ errors[index]?.last_name[0] }}
                  </div>
                </div>
                <div class="col-6">
                  <label>Phone Number<span style="color: red">*</span></label>
                  <input
                    type="number"
                    class="form-control"
                    name="phone_number"
                    v-model="item.mobile"
                    :disabled="index === 0"
                  />
                  <div class="error" v-if="errors[index]?.phone_number">
                    {{ errors[index]?.phone_number[0] }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <label>ID Proof<span style="color: red">*</span></label>
                  <input
                    type="file"
                    class="form-control"
                    name="id_proof"
                    @change="uploadProofImage(index, $event)"
                  />
                  <div class="error" v-if="errors[index]?.id_proof">
                    {{ errors[index]?.id_proof[0] }}
                  </div>
                </div>
                <div class="col-6 justify-end">
                  <button
                    type="button"
                    class="addDelete"
                    :disabled="index === 0"
                    @click="removeContent(index)"
                  >
                    <span class="plus danger">-</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="user_wrapper_outside justify-end"
            v-if="select_instance.persons.length < select_instance.person"
          >
            <button
              type="button"
              class="addDelete"
              @click="addContent(select_instance)"
            >
              <span class="plus">+</span>
            </button>
          </div>
          <div class="w-100 card_owner_other_wrapper">
            <div class="row align-center">
              <div class="col-6 justify-start">
                <p class="price">
                  <span class="rupee">₹</span
                  >{{
                    parseFloat(
                      select_instance.final_total
                        ? select_instance.final_total -
                            (select_instance.coupon_amount ? coupon_amount : 0)
                        : 0
                    )
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }}.00
                </p>
              </div>
              <div class="col-6 justify-end">
                <button
                  class="main_button_user mt-0"
                  :disabled="is_submit"
                  @click="proceed()"
                >
                  <span>Proceed</span>
                </button>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </div>
  </MasterLayout>
</template>

<script>
import MasterLayout from "./MasterLayout.vue";
import frame from "../../assets/images/User/Frame.png";
import axios from "axios";

export default {
  name: "BookingHistory",
  components: { MasterLayout },
  data() {
    return {
      main_name: "Booking Pending",
      link: [{ link: "booking-pending", name: "Booking Pending" }],
      frame: frame,
      token: localStorage.getItem("auth_cus_token"),
      bookingList: [],
      select_instance: {},
      errors: {},
      is_submit: false,
      userProfile: null,
    };
  },
  mounted() {
    if (!this.token) {
      this.$router.push({ name: "home" });
      window.location.href = "/";
    }
    this.getUserInfo();

    this.getBookingPending();

    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  methods: {
    async getUserInfo() {
      this.loader = true;
      await axios
        .get(process.env.VUE_APP_API_CUS_URL + "/user-profile", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          this.userProfile = response.data.data;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.removeItem("auth_otp");
            localStorage.removeItem("auth_user_type");
            localStorage.removeItem("auth_cus_token");

            this.removeLocalstorage();

            this.$router.push({ name: "home" });
            window.location.href = "/";
          }
        });
    },
    selectBooking(item) {
      const obj = {};
      if (item) {
        obj.check_in_date = item.booking_detail.check_in_date;
        obj.check_out_date = item.booking_detail.check_out_date;
        obj.gst_tax = item.booking_detail.gst_tax;
        obj.total_child = item.booking_detail.total_child;
        obj.coupon_code = null;
        obj.coupon_amount = item.booking_detail.coupon_amount;
        obj.booking_status = item.booking_status;
        obj.discount_amount = item.booking_detail.coupon_amount;
        obj.payment_mode_id = 2;
        obj.total = item.booking_detail.total;
        obj.extra_person_charge = 0;
        obj.festival_charge = 0;
        obj.gst_amount = item.booking_detail.gst_amount;
        obj.person = item.booking_detail.person;
        obj.sub_total = item.booking_detail.sub_total;
        obj.final_total = item.booking_detail.final_total;
        obj.free_for_friend = item.booking_detail.free_for_friend;
        obj.booking_id = item.person_details.booking_id;
        obj.villa_booking = item.villa_booking;
        obj.user_id = item.person_details.user_id;
        obj.free_for_friend = item.free_for_friend;
        obj.persons = [
          {
            id: item.person_details.user_id,
            first_name: item.person_details.first_name,
            middle_name: item.person_details.middle_name,
            last_name: item.person_details.last_name,
            villa_id: item.person_details.villa_id,
            is_primary: 1,
            user_type: item.person_details.user_type,
            mobile: item.person_details.mobile,
            id_proof: "",
          },
        ];
        obj.user_type = null;
        obj.id = null;
      }
      this.select_instance = obj;
    },
    cancelBooking(item) {
      const element = document.getElementById("row_" + item);
      element.classList.add("row_disabled");

      const payload = {
        booking_id: item,
      };

      axios
        .post(process.env.VUE_APP_API_CUS_URL + "/booking-cancel", payload, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.getBookingPending();
          }
        })
        .catch((e) => {
          element.classList.remove("row_disabled");
          if (e.response.status == 401) {
            localStorage.removeItem("auth_otp");
            localStorage.removeItem("auth_user_type");
            localStorage.removeItem("auth_cus_token");

            this.removeLocalstorage();

            this.$router.push({ name: "home" });
            window.location.href = "/";
          }
        });
    },
    addContent(item) {
      this.select_instance.persons.push({
        id: item.persons[0].id,
        first_name: "",
        middle_name: "",
        last_name: "",
        villa_id: item.persons[0].villa_id,
        is_primary: 0,
        user_type: "Guest",
        mobile: "",
        id_proof: "",
      });
    },
    removeContent(index) {
      this.select_instance.persons.splice(index, 1);
    },
    uploadProofImage(index, e) {
      let imgProof = e.target.files[0];
      this.createBase64Image(imgProof, index);
    },
    createBase64Image(fileObject, index) {
      this.errors = [];
      const reader = new FileReader();
      reader.onload = (e) => {
        const newImageEncode = e.target.result.split("base64,");
        this.select_instance.persons[index].id_tmp_proof = e.target.result;
        this.select_instance.persons[index].id_proof = newImageEncode[1];
      };
      reader.readAsDataURL(fileObject);
    },
    async proceed() {
      this.is_submit = true;
      this.errors = [];

      for (
        let index = 0;
        index < this.select_instance.persons.length;
        index++
      ) {
        const element = this.select_instance.persons[index];
        this.errors.push({});
        if (!element.first_name || element.first_name == "") {
          if (this.errors[index]) {
            this.errors[index]["first_name"] = ["Please enter first name."];
            this.is_submit = false;
            return false;
          }
        } else if (!element.last_name || element.last_name == "") {
          if (this.errors[index]) {
            this.errors[index]["last_name"] = ["Please enter last name."];
            this.is_submit = false;
            return false;
          }
        } else if (!element.mobile || element.mobile == "") {
          if (this.errors[index]) {
            this.errors[index]["phone_number"] = ["Please enter phone number."];
            this.is_submit = false;
            return false;
          }
        } else if (!element.id_proof || element.id_proof == "") {
          if (this.errors[index]) {
            this.errors[index]["id_proof"] = ["Please select ID Proof."];
            this.is_submit = false;
            return false;
          }
        }

        if (this.select_instance.persons.length < this.select_instance.person) {
          if (
            this.errors.length > 0 &&
            this.errors[index - 1]?.id_proof != []
          ) {
            this.$toast.error("Please add the all members details.");
            this.is_submit = false;
            return false;
          }
        }
      }

      const payload = {
        check_in_date: this.select_instance.check_in_date,
        check_out_date: this.select_instance.check_out_date,
        gst_tax: this.select_instance.gst_tax,
        total_child: this.select_instance.total_child,
        coupon_code: null,
        coupon_amount: this.select_instance.coupon_amount,
        booking_status: 5,
        discount_amount: this.select_instance.discount_amount,
        payment_mode_id: 2,
        total: this.select_instance.total,
        extra_person_charge: this.select_instance.extra_person_charge,
        festival_charge: this.select_instance.festival_charge,
        gst_amount: this.select_instance.gst_amount,
        person: parseInt(this.select_instance.person),
        sub_total: this.select_instance.sub_total,
        final_total: this.select_instance.final_total,
        free_for_friend: this.select_instance.free_for_friend,
        villa_booking: [this.select_instance.villa_booking],
        persons: this.select_instance.persons,
        free_for_friend: this.select_instance.free_for_friend,
        user_type: null,
        id: null,
      };

      var free_for_friend = this.select_instance.free_for_friend;

      await axios
        .post(
          process.env.VUE_APP_API_CUS_URL +
            "/villa-book-update/" +
            this.select_instance.booking_id,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then(async (response) => {
          if (response.data.code == 200 && free_for_friend == 0) {
            await axios
              .post(
                process.env.VUE_APP_API_CUS_URL + "/pay-online",
                {
                  device: "web",
                  amount: this.select_instance.final_total,
                  mobileNumber: this.select_instance.persons[0].mobile,
                  redirectMode: "REDIRECT",
                  user_id: this.select_instance.user_id,
                  booking_id: this.select_instance.booking_id,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: `Bearer ${this.token}`,
                  },
                }
              )
              .then((response) => {
                if (response.data.links) {
                  // this.is_submit = false;
                  window.location.href = response.data.links.pay_link_web;
                }
              })
              .catch((e) => {
                console.log("e: ", e);
                if (e.response.status == 401) {
                  localStorage.removeItem("auth_otp");
                  localStorage.removeItem("auth_user_type");
                  localStorage.removeItem("auth_cus_token");

                  this.removeLocalstorage();

                  this.$router.push({ name: "home" });
                  window.location.href = "/";
                }
              });
          } else if (response.data.code == 200 && free_for_friend == 1) {
            this.$router.push({ name: "thanks" });
          }
        })
        .catch((e) => {
          console.log("e: ", e);
          this.is_submit = false;
          if (e.response.status == 401) {
            localStorage.removeItem("auth_otp");
            localStorage.removeItem("auth_user_type");
            localStorage.removeItem("auth_cus_token");

            this.removeLocalstorage();

            this.$router.push({ name: "home" });
            window.location.href = "/";
          }
        });
    },
    async getBookingPending() {
      this.loader = true;
      await axios
        .get(process.env.VUE_APP_API_URL + "/owner/other-booking-list", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          this.bookingList = response.data.data.data;
          // console.log('history: ', this.bookingList);
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.removeItem("auth_otp");
            localStorage.removeItem("auth_user_type");
            localStorage.removeItem("auth_cus_token");

            this.removeLocalstorage();

            this.$router.push({ name: "home" });
            window.location.href = "/";
          }
        });
    },
    removeLocalstorage() {
      localStorage.removeItem("id");
      localStorage.removeItem("check_in_date");
      localStorage.removeItem("check_out_date");
      localStorage.removeItem("total_child");
      localStorage.removeItem("total_adult");
      localStorage.removeItem("qty");

      localStorage.removeItem("total");
      localStorage.removeItem("festival_charge");
      localStorage.removeItem("extra_person_charge");
      localStorage.removeItem("gst_tax");
      localStorage.removeItem("gst_tax_percent");
    },
  },
};
</script>

<style scoped>
.mbr_detail_col_inr {
  background: #fff;
  margin-bottom: 10px;
  border-radius: 9px;
}

.mbr_detail_col_inr:hover {
  box-shadow: 0px 4px 4px #80808085;
  border-radius: 9px;
  cursor: pointer;
}

.villa_img {
  height: 50px;
}

.villa_img img {
  width: 50px;
  height: 50px;
  border-radius: 9px;
  margin-right: 5px;
}

.mbr_detail_name,
.mbr_detail_ul,
.qr_href {
  align-items: center;
  display: flex;
  text-transform: capitalize;
}

.mbr_detail_block {
  padding: 10px;
}

.to_grey {
  color: grey;
}

.bold_font {
  font-weight: 600;
}

.light_font {
  font-weight: 300;
  line-height: 24px;
  text-align: left;
}

.light_font .bold_font {
  display: block;
}

.row_disabled .btn-danger {
  cursor: not-allowed;
  opacity: 0.8;
}

.mbr_detail_li {
  margin-right: 10px;
}

.qr_div {
  justify-content: end;
}

.name_li {
  width: 120%;
}

.badge {
  padding: 5px;
  border-radius: 10px;
  color: #fff;
  width: 70px;
}

.badge-info {
  background-color: rgb(100, 198, 237);
}

.badge-success {
  background-color: rgb(100, 237, 132);
}

.badge-warning {
  background-color: rgb(237, 189, 100);
}

.badge-danger {
  background-color: rgb(237, 100, 100);
}

.width_40 {
  width: 40%;
}

.text-white {
  color: #fff !important;
}

.btn-primary {
  background-color: #42aea3;
  border-color: #42aea3;
}

#dm .ml-2 {
  margin-left: 10px;
}

#dm .user_wrapper {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 14px #dceae8;
  background: #fff;
  padding: 30px;
  margin-top: 20px;
  position: relative;
}

#dm .user_wrapper .row {
  display: flex;
}

#dm .user_wrapper .row .col-6 {
  display: block;
  width: 50%;
  padding: 15px 10px;
}

#dm .user_wrapper .row .col-6 label {
  width: 100%;
  text-align: left;
  margin-bottom: 5px;
}

#dm .user_wrapper .row .col-6 input {
  border: 0;
  outline: 0;
  background-color: transparent;
  font-size: 16px !important;
  width: inherit;
  border-bottom: 1px solid #a2dfc7;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  width: 100%;
}

#dm .user_wrapper .row .error {
  text-align: left;
  margin-top: 10px;
  color: red;
  font-size: 10.5px;
}

.addDelete {
  border: none;
  background: none;
}

.justify-end {
  justify-content: end;
  display: flex !important;
}

.plus.danger {
  background-color: red !important;
}

button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.user_wrapper_outside {
  padding: 15px;
}

.user_wrapper_outside button {
  padding: 13px;
}

.w-100 {
  width: 100% !important;
}

#dm .card_owner_other_wrapper {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 14px #dceae8;
  background: #fff;
  padding: 30px;
  margin-top: 20px;
}

.align-center {
  display: flex;
  align-items: center;
}

.form-control[disabled],
fieldset[disabled] .form-control {
  background: #00000013 !important;
}

.li_index {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 30px;
  height: 30px;
  background: #42aea3;
  color: #fff;
  line-height: 30px;
  border-radius: 20px 28px 28px 30px;
}
</style>
